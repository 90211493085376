@use "sass:map";

$primary:       #f9b122;
/*$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;*/

@import "../node_modules/bootstrap/scss/bootstrap";

body {

  background-color: #f5f5f5;
}

::-moz-selection {
  color: #fff;
  background: $primary;
}
::selection {
  color: #fff;
  background: $primary;
}

.container.nav-container {
  display: flex;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0;
  padding-bottom: 0;
  flex-direction: row;
  align-items: center;
}

@keyframes bounce {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateY(-50%);
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translatey(100px) scale(0.2);
  }
  50% {
    transform: translatey(-200px) scale(1);
  }
  100% {
    transform: translatey(100px) scale(0.2);
  }
}

.drone {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;

  img {
    animation: float 30s ease-in-out infinite;
    transform: translatey(100px) scale(0.2);
    width: 50%;
  }
}


.nav {
  position: fixed;
  top: 0;
  padding: 45px 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  z-index: 1000;
  width: 100%;
  transition: background-color .3s ease, box-shadow .2s ease, padding .5s ease;
}

.navbar-collapse {
  flex-grow: initial;
}

body.scrolled {
  .nav {
    padding: 2px 0;
    background-color: #fff;
    box-shadow: 0 1px 8px 2px rgb(0 0 0 / 30%);
  }

  .scroll-down i {
    opacity: 0;
  }
}

.fullpage {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
  /*background: #B2B2B2;
  background: -moz-linear-gradient(-45deg, #B2B2B2 0%, #EAEAEA 100%);
  background: -webkit-linear-gradient(-45deg, #B2B2B2 0%, #EAEAEA 100%);;
  background: linear-gradient(135deg, #B2B2B2 0%, #EAEAEA 100%);;*/
  background-image: url(/img/hero-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  .scroll-down {
    animation: bounce 2s ease-in-out infinite;
    bottom: 5rem;
    color: #fff;
    left: 50%;
    opacity: 1;
    position: absolute;
    text-align: center;

    i {
      margin-left: -50%;
      font-size: 80px;
      transition: opacity .5s ease;
    }
  }
}


.nav-menu {
  display: flex;
  padding: 0;
  flex-direction: row;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.nav-logo {
  font-size: 18px;
}

.nav-link {
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  padding: 4px 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(63, 95, 246, 0);
  border-radius: 16px;
  transition: border-color 200ms ease,  background-color 300ms ease;
  color: #423f3f;
  font-size: 16px;
  text-align: center;
  text-decoration: none;

  &.active {
    background-color: $primary;
    .nav-link-text {
      text-shadow: 1px 0 0 currentColor;
      color: #473300;
    }
  }
  &:not(.flag):hover {
    border-style: solid;
    border-width: 2px;
    border-color: $primary;
    -webkit-transition: box-shadow 300ms ease, color 200ms ease, background-color 300ms ease;
    transition: box-shadow 300ms ease, color 200ms ease, background-color 300ms ease;
    text-decoration: none;
  }

  .nav-link-text {
    color: #000;
    line-height: 20px;
    text-decoration: none;
  }
}


img {
  max-width: 100%;
}

section {
  padding: 25px 0;

  &.dark {
    background: #eee;
  }
}

.section-title {
  position: relative;
  text-align: center;
  margin-bottom: 60px;
  .top-title {
    font-size: 20px;
    font-weight: 600;
    position: relative;
    color: $primary;
    text-transform: uppercase;
  }
  .main-title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 0px;
    color: #aaa;
    position: relative;
    text-transform: uppercase;
    font-weight: 200;
  }
}

.form-box {
  position: relative;
}

.form-double {
  display: flex;
}

.page {
  padding-top: 50px;
}

html.scroll {
  overflow-y: hidden;
  body {
    overflow-y: hidden;
  }
}

.news-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 20px;

  .news-item {
    background: #eee;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    transition: box-shadow .2s ease;

    &:hover {
      box-shadow: 0 0 15px 1px rgba(0, 0, 0, .1);
    }

    a {
      text-decoration: none;
      color: #333;

      .picture {
        height: 200px;
        display: block;
        overflow: hidden;

        img {
          width: auto;
          position: relative;
        }
      }

      .details {
        padding: 10px;
        display: flex;
        flex: 1;
        flex-direction: column;

        .title {
          display: block;
          border-left: 4px solid $primary;
          padding-left: 5px;
          font-weight: bold;
          position: relative;
          z-index: 1;
          overflow: hidden;
          transition: color .3s ease;

          &:before {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            background: $primary;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            transform: translateX(-100%);
            transition: transform .3s ease;
          }
        }

        .date {
          display: block;
          font-style: italic;
          font-size: 12px;
          border-left: 4px solid $primary;
          padding-left: 5px;
        }
      }

      &:hover {
        .title {
          /*background: $primary;*/
          color: #fff;

          &:before {
            transform: translateX(0);
          }
        }
      }
    }
  }
}

.popup-container {
  display: none;
  flex: 1;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  justify-content: center;
  z-index: 99999;
  overflow-y: scroll;

  &.visible {
    display: block;
  }

  .popup-back {
    background: rgba(0, 0, 0, .3);
    display: flex;
    flex: 1;
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
  }

  .popup {
    background: #fff;
    max-width: 1000px;
    z-index: 100;
    position: relative;
    display: block;
    box-shadow: 0 0 100px 0 rgba(0, 0, 0, .3);
    margin: 50px auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;


    .intro {
      border-bottom: 1px solid #eee;
      text-align: center;
      font-style: italic;
      padding: 25px 50px 25px 50px;
      background: #eee;
      margin-top: 25px;
    }

    .popup-content {
      padding: 0 50px 50px 50px;
      background: #fff;
      border-bottom: 10px solid $primary;

      .text {
        display: flex;
        gap: 20px;
        flex-direction: column;

        .caption {
          font-size: 11px;
          font-style: italic;
          text-align: center;
          margin-top: -20px;
        }

        img {
          align-self: center;
        }

        p {
          margin: 0;
          text-align: justify;

          &.marg {
            margin-bottom: 1rem;
          }
        }
      }
    }

    .image {
      display: flex;
      height: 400px;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      position: relative;


      img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        filter: blur(13px);
      }

      .name {
        font-size: 28px;
        font-weight: bold;
        position: relative;
        background: $primary; //rgba(255, 255, 255, .7);
        color: #fff;
        padding: 5px 15px;
        //display: block;
        //width: 100%;
        text-align: center;
        box-shadow: 0 0 44px 20px rgb(0 0 0 / 30%);
      }
    }

  }
}

.partners {
  list-style: none;
  display: grid;
  gap: 50px;
  grid-template-columns: auto auto auto;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.project {
  /*border-bottom: 1px solid rgba(0, 0, 0, .1);*/

  a {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #000;
    text-decoration: none;
    padding: 20px;
    border-radius: 10px;
    transition: box-shadow 300ms ease, color 200ms ease, background-color 300ms ease;

    &:hover {
      //box-shadow: inset 0px 0px 0px 5px #f9b122;
      //box-sizing: border-box;
      background: #dedede;
    }

    .name {
      font-weight: bold;
      display: block;
      font-size: 20px;
      text-transform: uppercase;
    }

    .details {
      display: inline-flex;
      flex: 1;
      gap: 30px;

      .cover {
        display: flex;
        flex: 0.4;
        align-items: start;

        img {
          border-radius: 15px;
          overflow: hidden;
        }
      }

      .description {
        display: flex;
        flex: 1;
        text-align: justify;
        margin: 0;
      }
    }
  }
}

.team-member {

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .avatar {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 20px;
  }

  .info {
    padding-left: 10px;
    flex: 1;
  }

  .avatar img {
    display: block;
    width: 100%;
  }

  .name {
    font-weight: bold;
    display: block;
    color: #000;
  }

  .role {
    font-style: italic;
    display: block;
    font-family: "Times New Roman", serif;
  }

  .intro {
    text-align: justify;
    padding: 10px 0 10px 20px;
    border-left: 5px solid $primary;
    margin-left: 10px;
    font-size: 12px;
    font-style: italic;
  }
}

input:focus, textarea:focus {
  box-shadow: 0px 0px 1px 2px inset $primary;
  border-color: $primary!important;
}

.contact-form .input-box {
  margin-bottom: 20px;
}

.contact-form .form-double .form-box {
  display: flex;
  flex: 1;
  margin-right: 30px;
}
.contact-form .form-double .form-box.left {
  margin-right: 0;
}
.form-box .icon {
  position: absolute;
  right: 5px;
  top: 12px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100px;
  color: #868a98;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.input-box {
  padding: 18px 20px;
  width: 100%;
  background-color: #ffffff;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid #ededed;
  border-radius: 5px;
  outline: none;
  color: #0e2129;
}

.copyright-text {
  margin-top: 30px;
  color: #828282;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .footer-logo {
    display: inline-block;
    background-image: url(/img/mouldtech-bw.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 37px;
    width: 200px;
    text-indent: -999999px;

    &:hover {
      background-image: url(/img/mouldtech.png);
    }
  }
}

.footer-bottom {
  padding: 50px 0;

  a {
    text-decoration: none;
    font-weight: bold;
  }
}

@media only screen and (max-width: map.get($container-max-widths, "md")) {
  .project {
    a {
      display: flex;
      flex-direction: column;

      .details {
        gap: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .partners {
    gap: 25px;
    grid-template-columns: auto auto;
  }

  .team-member {
    .intro {
      padding: 10px 0 10px 0px;
      border-left: none;
      margin-left: 0;
    }
  }

  .news-list {
    display: flex;
    grid-auto-columns: unset;
    grid-auto-flow: unset;
    flex-direction: column;
  }
}
